import { useState } from 'react';
import { get as getCookie } from 'js-cookie';

export type UserParameters = {
  ip: string;
  userAgent: string;
  userCountry: string | null;
  fbp: string | undefined;
  fbc: string | undefined;
};

export const useUserParameters = () => {
  const [userParameters, setUserParameters] = useState<UserParameters>();

  const fetchUserParameters = async () => {
    const userIp = await fetch('/api/user-ip')
      .then((response) => response.json())
      .catch(() => ({ data: 'unknown' }));

    const userAgent = await fetch('/api/user-agent')
      .then((response) => response.json())
      .catch(() => ({ data: 'unknown' }));

    const userLocation = await fetch('/api/geo-location')
      .then((response) => response.json())
      .catch(() => ({ location: {} }));

    const data = {
      ip: userIp?.data,
      userAgent: userAgent?.data,
      userCountry: userLocation.location.country,
      fbp: getCookie('_fbp'),
      fbc: getCookie('_fbc')
    };

    setUserParameters(data);

    return data;
  };

  return { userParameters, fetchUserParameters };
};
