import React, { type FC } from 'react';
import { Box, cn } from '@superside/ui';

export type ProgressBarProps = {
  progress: number;
  isError?: boolean;
  className?: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({ progress = 0, isError, className }) => (
  <Box
    data-testid='progress-bar'
    className={cn('bg-light relative h-[1.5px] w-full rounded', className)}
  >
    <Box
      data-testid='progress-bar-inner'
      style={{ width: `${progress}%` }}
      className={cn(
        'absolute -left-px top-0 h-1 w-full translate-y-[-40%] rounded transition-all duration-[0.5s] ease-[ease-out]',
        isError ? 'bg-bor-error-500' : 'bg-bor-primary'
      )}
    />
  </Box>
);
