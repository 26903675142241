import { useState } from 'react';

export type ApolloPerson = {
  id: string;
  first_name: string | null;
  last_name: string | null;
  name: string | null;
  linkedin_url: string | null;
  title: string | null;
  headline: string | null;
  email: string;
  organization_id: string;
  organization?: {
    name: string;
    annual_revenue: number | null;
    industry: string;
    website_url: string | null;
    linkedin_url: string | null;
    estimated_num_employees: number | null;
    total_funding: number | null;
    city: string | null;
    state: string | null;
    country: string | null;
  };
  is_likely_to_engage: boolean;
  state: string | null;
  city: string | null;
  country: string | null;
};

export const useApollo = () => {
  const [apolloData, setApolloData] = useState<ApolloPerson | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);

  const fetchApolloData = async ({ email }: { email: string }) => {
    try {
      const response = await fetch('/api/apollo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch Apollo data');
      }

      const data = (await response.json()) as ApolloPerson;

      setApolloData(data);

      return data;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    }
  };

  return { apolloData, error, fetchApolloData };
};
