import get from 'lodash/get';
import type { SanityProgressiveForm } from 'sanity.types';
import type { ApolloPerson, UserParameters } from '../../../../../hooks';
import type {
  ProgressiveFormField,
  ProgressiveFormFieldEnrichAnswerCondition,
  ProgressiveFormFieldEnrichAnswerConditionalLogic
} from '../types';

export const fieldEnrich = ({
  form,
  field,
  apolloData,
  userParameters,
  urlQuery,
  eventId
}: {
  form: SanityProgressiveForm;
  field: ProgressiveFormField;
  apolloData: ApolloPerson | undefined;
  userParameters: UserParameters | undefined;
  urlQuery: Record<string, string | string[] | undefined>;
  eventId: string;
}) => {
  const { enrichAnswer } = field;

  const { enabled, type, enrichProperty, conditionalLogic, customValue } = enrichAnswer || {};

  if (enabled) {
    if (type === 'apollo' && enrichProperty && apolloData) {
      const apolloValue = get(apolloData, enrichProperty, '');

      if (apolloValue) {
        if (
          conditionalLogic &&
          conditionalLogic.enabled &&
          conditionalLogic.conditions &&
          conditionalLogic.conditions.length > 0
        ) {
          const { shouldEnrichField } = evaluateEnrichConditionalLogic(
            conditionalLogic,
            apolloValue
          );

          return { shouldEnrichField, enrichValue: apolloValue };
        }

        return { shouldEnrichField: true, enrichValue: apolloValue };
      }
    }

    if (
      type === 'userParameter' &&
      userParameters &&
      enrichProperty &&
      enrichProperty in userParameters
    ) {
      const userParameterValue = userParameters[enrichProperty];

      if (userParameterValue) {
        return { shouldEnrichField: true, enrichValue: userParameterValue };
      }
    }

    if (type === 'formProperty' && enrichProperty) {
      if (enrichProperty === 'eventId') {
        return { shouldEnrichField: true, enrichValue: eventId };
      }

      if (enrichProperty === 'formTitle') {
        return { shouldEnrichField: true, enrichValue: form.title };
      }
    }

    if (type === 'custom' && customValue) {
      return { shouldEnrichField: true, enrichValue: customValue };
    }

    if (type === 'queryParam' && urlQuery[field.name]) {
      return { shouldEnrichField: true, enrichValue: urlQuery[field.name] };
    }
  }

  return { shouldEnrichField: false, enrichValue: null };
};

const evaluateEnrichConditionalLogic = (
  conditionalLogic: ProgressiveFormFieldEnrichAnswerConditionalLogic,
  enrichValue: string | number | boolean
) => {
  const { action, logicalOperator, conditions } = conditionalLogic;

  const result = evaluateConditions(conditions!, logicalOperator!, enrichValue);

  return { shouldEnrichField: action === 'enrich' ? result : !result };
};

const evaluateConditions = (
  conditions: Array<ProgressiveFormFieldEnrichAnswerCondition>,
  logicalOperator: 'AND' | 'OR',
  enrichValue: string | number | boolean
): boolean => {
  return logicalOperator === 'AND'
    ? conditions.every((condition) => evaluateEnrichCondition(condition, enrichValue))
    : conditions.some((condition) => evaluateEnrichCondition(condition, enrichValue));
};

const evaluateEnrichCondition = (
  condition: ProgressiveFormFieldEnrichAnswerCondition,
  enrichValue: string | number | boolean
): boolean => {
  const matchValue = condition.matchValue;

  switch (condition.operator) {
    case 'equal':
      return enrichValue === matchValue;
    case 'notEqual':
      return enrichValue !== matchValue;
    case 'contain':
      return String(enrichValue).includes(matchValue!);
    case 'notContain':
      return !String(enrichValue).includes(matchValue!);
    case 'startWith':
      return String(enrichValue).startsWith(matchValue!);
    case 'endWith':
      return String(enrichValue).endsWith(matchValue!);
    default:
      return false;
  }
};
