import { useEffect } from 'react';
import { TRACKING_EVENT_NAMES, userEventsTracking, useAnalytics } from '@superside/analytics';
import { useAtomMutator } from '@superside/atoms/src';
import { chilipiperLoaderAtom } from '@superside/lego/src/components/ChilipiperLoader/chilipiperLoaderAtom';

const CHILI_PIPER_CONFIG = {
  domain: 'superside',
  options: {
    title: 'Thanks! What time works best for a quick call?',
    map: true
  }
};

type ChiliPiperSubmissionOptions = {
  lead: Record<string, string>;
  routerName: string;
};

export const useChiliPiper = ({ enabled, eventId }: { enabled: boolean; eventId: string }) => {
  const { track } = useAnalytics();
  const setChiliPiperLoader = useAtomMutator(chilipiperLoaderAtom);

  const hideChiliPiperLoader = () =>
    setChiliPiperLoader({ enabled: false, name: null, company: null });

  const trackUserEvent = (eventName: string, extraProperties = {}) => {
    track(eventName, {
      user: {
        ...userEventsTracking
      },
      provider: 'chilipiper',
      ...extraProperties
    });
  };

  const submitChiliPiperFormAndScheduleCall = async ({
    lead,
    routerName
  }: ChiliPiperSubmissionOptions) => {
    return new Promise((resolve, reject) => {
      setChiliPiperLoader({ enabled: true, name: lead.firstname, company: lead.companyName });

      let submitSuccess = false;

      if (!window.ChiliPiper) {
        throw new Error(`ChiliPiper is not available`);
      }

      const availabilityLoadedHandler = (event: { data: { action: any } }) => {
        const action = event?.data?.action;

        if (action === 'availability-loaded') {
          hideChiliPiperLoader();
          trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_START);
        }

        if (action === 'no-free-slots') {
          const popupElement = document.querySelector('.chilipiper-popup');

          if (popupElement) {
            popupElement.remove();
          }

          window.ChiliPiper.submit(CHILI_PIPER_CONFIG.domain, 'fallback_router', {
            ...payloadObject,
            map: true
          });
        }
      };

      const payloadObject = {
        ...CHILI_PIPER_CONFIG.options,
        onSuccess: (e: unknown) => {
          submitSuccess = true;

          trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_SUBMISSION);
          window.removeEventListener('message', availabilityLoadedHandler, false);

          hideChiliPiperLoader();
          setTimeout(() => {
            resolve(e);
          }, 5000);
        },
        onError: (e: { message: string }) => {
          const eventName = e.message?.toLowerCase().replace(/\s/g, '-') || 'error';

          trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_FAILURE, { error: eventName });
          window.removeEventListener('message', availabilityLoadedHandler, false);
          hideChiliPiperLoader();
          reject(e);
        },
        onClose: (e: unknown) => {
          if (submitSuccess) {
            resolve(e);
          } else {
            trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_CLOSED);
            window.removeEventListener('message', availabilityLoadedHandler, false);
            hideChiliPiperLoader();
            reject(e);
          }
        },
        event: {
          eventId
        },
        lead
      };

      window.addEventListener('message', availabilityLoadedHandler, false);

      window.ChiliPiper.submit(CHILI_PIPER_CONFIG.domain, routerName, payloadObject);
    });
  };

  const submitChiliPiperForm = async ({ lead, routerName }: ChiliPiperSubmissionOptions) => {
    const POST_URL = `https://api.chilipiper.com/marketing/${CHILI_PIPER_CONFIG.domain}`;

    const res = await fetch(POST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        form: lead,
        options: {
          ...CHILI_PIPER_CONFIG.options,
          router: routerName
        },
        event: {
          eventId
        }
      })
    });

    if (res.ok) {
      return Promise.resolve();
    } else {
      const errorMessage = await res.json();

      // eslint-disable-next-line no-console
      console.error(errorMessage);

      return Promise.reject(new Error(`Sorry, something went wrong`));
    }
  };

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!window.ChiliPiper) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = 'https://superside.chilipiper.com/concierge-js/cjs/concierge.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, [enabled]);

  return {
    submitChiliPiperForm,
    submitChiliPiperFormAndScheduleCall
  };
};
