import { useState, useCallback, useMemo, useEffect } from 'react';
import type { SanityProgressiveForm } from 'sanity.types';
import { useEventId } from '@superside/lego/src/utils/form';
import type { ProgressiveFormValues } from '../types';
import { useProgressiveFormFields } from './useProgressiveFormFields';
import { useProgressiveFormSubmission } from './useProgressiveFormSubmission';

export const useProgressiveForm = (props: {
  form: SanityProgressiveForm;
  initialValues: ProgressiveFormValues | undefined;
  onFormSuccess: (() => void) | undefined;
  onFormError: (() => void) | undefined;
}) => {
  const { form, initialValues, onFormSuccess, onFormError } = props;

  const { fields } = form;

  const [isPreparing, setIsPreparing] = useState(false);

  const eventId = useEventId();

  const formInitialValues = useMemo(
    () =>
      fields!.reduce(
        (acc, { name }) => ({ ...acc, [name]: initialValues ? initialValues[name] || '' : '' }),
        {}
      ),
    [fields, initialValues]
  );

  const [formValues, setFormValues] = useState<ProgressiveFormValues>({
    ...formInitialValues
  });

  const {
    currentField,
    currentFieldIndex,

    nonHiddenFields,
    isCurrentFieldFirstField,

    goToPrevField,
    goToNextField,

    getFormValues
  } = useProgressiveFormFields({ form, eventId });

  const { handleSubmit: submitForm } = useProgressiveFormSubmission({
    form,
    eventId,
    onFormSuccess,
    onFormError
  });

  const formProgress = useMemo(
    () => Math.round((currentFieldIndex + 1) * (100 / nonHiddenFields?.length + 1)),
    [currentFieldIndex, nonHiddenFields]
  );

  const prepareForm = useCallback(async () => {
    setIsPreparing(true);

    const { formValues: newFormValues } = await getFormValues(formInitialValues);

    setFormValues(newFormValues);
    setIsPreparing(false);
  }, [formInitialValues, getFormValues]);

  const handleNext = useCallback(
    async (values: ProgressiveFormValues) => {
      const { formValues: newFormValues, isCurrentFieldLastField } = await getFormValues(values);

      setFormValues(newFormValues);

      if (isCurrentFieldLastField) {
        return submitForm({ ...formValues, ...newFormValues });
      }

      goToNextField();
    },
    [formValues, submitForm, goToNextField, getFormValues]
  );

  const handlePrev = useCallback(() => {
    if (!isCurrentFieldFirstField) {
      goToPrevField();
    }
  }, [isCurrentFieldFirstField, goToPrevField]);

  useEffect(() => {
    prepareForm();
    // !important: do not add dependencies here
  }, []);

  return {
    isPreparing,

    formProgress,
    formInitialValues,

    currentField,
    currentFieldIndex,

    handleNext,
    handlePrev
  };
};
