import React, { useCallback, type FC } from 'react';
import toast from 'react-hot-toast';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { AnimatePresence, motion } from 'framer-motion';
import type { SanityProgressiveForm } from 'sanity.types';
import { Box, Loader, ToastBody } from '@superside/ui';
import { ProgressBar, PrevNextButtons, FormField } from './components';
import { useProgressiveForm } from './hooks';
import type { ProgressiveFormValues } from './types';
import { progressiveFormConsentFieldName } from './constants';

const MotionBox = motion(Box);

export type FormProps = {
  form: SanityProgressiveForm;
  initialValues?: ProgressiveFormValues;
  onFormSuccess?: () => void;
  onFormError?: () => void;
};

export const Form: FC<FormProps> = (props) => {
  const { form, initialValues, onFormError, onFormSuccess } = props;

  const { consent } = form;

  const handleFormError = useCallback(() => {
    toast.custom(
      (t) => (
        <ToastBody
          toastId={t.id}
          config={{
            text:
              form.toastErrorMessage ||
              "Oh no, something went wrong! Somewhere in the process an error occurred and your request couldn't be processed. Please try again",
            type: 'error',
            countDownDuration: 10
          }}
        />
      ),
      {
        duration: 10000
      }
    );
    onFormError?.();
  }, [form.toastErrorMessage, onFormError]);

  const {
    isPreparing,
    formProgress,
    formInitialValues,

    currentField,
    currentFieldIndex,

    handlePrev,
    handleNext
  } = useProgressiveForm({
    form,
    initialValues,
    onFormError: handleFormError,
    onFormSuccess
  });

  if (isPreparing) {
    return <Loader fullScreen />;
  }

  return (
    <FinalForm
      initialValues={{ ...formInitialValues, [progressiveFormConsentFieldName]: false }}
      onSubmit={handleNext}
      render={(formProps) => {
        const { handleSubmit, submitting, valid, touched } = formProps;

        return (
          <>
            <form
              data-testid='progressive-form'
              className='relative flex h-full w-full flex-[0_0_auto] flex-col justify-center gap-4'
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Box className='relative justify-end'>
                <Box className='flex-row items-center justify-between gap-2'>
                  <AnimatePresence mode='wait'>
                    <MotionBox
                      key={currentFieldIndex}
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: 10, opacity: 0 }}
                      className='flex-1 items-end'
                    >
                      <FormField
                        field={currentField}
                        disabled={submitting}
                        withConsent={consent?.enabled && consent?.field === currentField.name}
                      />
                    </MotionBox>
                  </AnimatePresence>

                  <PrevNextButtons
                    withBack={currentFieldIndex !== 0}
                    isLoading={submitting}
                    onNext={handleSubmit}
                    onPrev={handlePrev}
                  />
                </Box>
              </Box>

              <ProgressBar
                progress={formProgress}
                isError={!valid && touched?.[currentField.name]}
              />
            </form>

            <FormSpy
              subscription={{
                active: true,
                modified: true,
                valid: true,
                validating: true,
                values: true
              }}
              onChange={(formState) => {
                // if the current field is grid-select and not multiple, and the field has been modified and valid, we submit the form
                if (
                  formState.modified?.[currentField.name] &&
                  formState.active === currentField.name &&
                  !formState.validating &&
                  formState.valid &&
                  formState.values[currentField.name] &&
                  currentField.type === 'grid-select' &&
                  !currentField?.multiple
                ) {
                  handleSubmit();
                }
              }}
            />
          </>
        );
      }}
    />
  );
};
