import type {
  ProgressiveFormField,
  ProgressiveFormFieldCondition,
  ProgressiveFormFieldConditionGroup,
  ProgressiveFormValues
} from '../types';

export const evaluateConditionalLogic = (
  field: ProgressiveFormField,
  formValues: ProgressiveFormValues
): { isFieldVisible: boolean } => {
  const { conditionalLogic } = field;

  if (
    !conditionalLogic ||
    !conditionalLogic.enabled ||
    !conditionalLogic.conditions ||
    !conditionalLogic.action ||
    !conditionalLogic.logicalOperator ||
    conditionalLogic.conditions.length < 1
  ) {
    return { isFieldVisible: true };
  }

  const result = evaluateConditions(
    conditionalLogic.conditions,
    formValues,
    conditionalLogic.logicalOperator
  );

  return { isFieldVisible: conditionalLogic.action === 'show' ? result : !result };
};

const evaluateConditions = (
  conditions: Array<ProgressiveFormFieldCondition | ProgressiveFormFieldConditionGroup>,
  formValues: ProgressiveFormValues,
  logicalOperator: 'AND' | 'OR'
): boolean => {
  return logicalOperator === 'AND'
    ? conditions.every((condition) => evaluateConditionOrGroup(condition, formValues))
    : conditions.some((condition) => evaluateConditionOrGroup(condition, formValues));
};

const evaluateConditionOrGroup = (
  condition: ProgressiveFormFieldCondition | ProgressiveFormFieldConditionGroup,
  formValues: ProgressiveFormValues
): boolean => {
  if (condition._type === 'conditionGroup') {
    return evaluateConditions(
      condition.conditions || [],
      formValues,
      condition.logicalOperator || 'AND'
    );
  } else {
    return evaluateSingleCondition(condition, formValues);
  }
};

const evaluateSingleCondition = (
  condition: ProgressiveFormFieldCondition,
  formValues: ProgressiveFormValues
): boolean => {
  const fieldValue = formValues[condition.conditionField];
  const matchValue = condition.matchValue;

  switch (condition.operator) {
    case 'equal':
      return fieldValue === matchValue;
    case 'notEqual':
      return fieldValue !== matchValue;
    case 'contain':
      return String(fieldValue).includes(matchValue!);
    case 'notContain':
      return !String(fieldValue).includes(matchValue!);
    case 'startWith':
      return String(fieldValue).startsWith(matchValue!);
    case 'endWith':
      return String(fieldValue).endsWith(matchValue!);
    case 'hasValue':
      return fieldValue !== undefined && fieldValue !== null && fieldValue !== '';
    case 'hasNoValue':
      return fieldValue === undefined || fieldValue === null || fieldValue === '';
    default:
      return false;
  }
};
