import type { SanityProgressiveForm } from 'sanity.types';
import type { ProgressiveFormValues } from '../types';

export const getLeadQualificationScore = async ({
  formValues,
  form
}: {
  formValues: ProgressiveFormValues;
  form: SanityProgressiveForm;
}) => {
  const { leadScorePayload } = form;

  const payload = leadScorePayload?.reduce((acc, item) => {
    if (formValues[item.field]) {
      acc[item.key] = formValues[item.field];
    }

    return acc;
  }, {});

  return fetch(`/api/lead-qualification`, {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then((data) => data.json())
    .catch((err) => {
      console.error('Error occurred while qualifying lead', err);
    });
};
