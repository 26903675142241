import { FREE_EMAIL_SERVICES } from '@superside/apps-common';
import type { ProgressiveFormField } from '../types';
import { topLevelDomains } from './topLevelDomainList';

const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[A-Za-z0-9][a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validPhoneNumberRegex = /^(?:\+\d{1,3}\s?)?[\d-]+$/;
const invalidEmailAnswer = 'Company email is required';

const shortFreeEmailValidation = (domain: string) =>
  FREE_EMAIL_SERVICES.includes(domain)
    ? { isValid: false, message: invalidEmailAnswer }
    : { isValid: true, message: '' };

const validateEmail = async ({ value, field }: { value: string; field: ProgressiveFormField }) => {
  const domain = value.split('@')[1];
  const userTopLevelDomain = domain?.split('.').pop();

  if (
    !validEmailRegex.test(value) ||
    (userTopLevelDomain && !topLevelDomains.includes(userTopLevelDomain.toUpperCase()))
  ) {
    return { isValid: false, message: `${field.label} is invalid` };
  }

  if (field.validation?.onlyCompanyEmail) {
    try {
      const response = await fetch(`/api/free-email-providers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ e: domain })
      });

      if (!response.ok) {
        return shortFreeEmailValidation(domain);
      }

      const { isFreeEmailDomain } = await response.json();

      if (isFreeEmailDomain) {
        return { isValid: false, message: invalidEmailAnswer };
      }
    } catch (error) {
      return shortFreeEmailValidation(domain);
    }
  }

  return { isValid: true, message: '' };
};

const validatePhoneNumber = ({ value, field }: { value: string; field: ProgressiveFormField }) => {
  return !validPhoneNumberRegex.test(value)
    ? { isValid: false, message: `${field.label} is invalid` }
    : { isValid: true, message: '' };
};

export const validateField = async ({
  field,
  value
}: {
  field: ProgressiveFormField;
  value: string;
}) => {
  const { validation, type, label } = field;

  if (!value && validation?.required) {
    return { isValid: false, message: `${label} is required` };
  }

  if (Array.isArray(value) && value.length === 0 && validation?.required) {
    return { isValid: false, message: `${label} is required` };
  }

  if (value) {
    switch (type) {
      case 'email':
        return await validateEmail({ value, field });
      case 'phone':
        return validatePhoneNumber({ value, field });
      default:
        return { isValid: true, message: '' };
    }
  }

  return { isValid: true, message: '' };
};
